<template>
  <div>
    <div class="allmsg">
      <div class="toptip">
        <div class="toppbu">
          <img class="logoimg" src="../../assets/users2.png" />
          <h2>专注数字化人力资源风控</h2>
          <h2>一键背调 还原真实</h2>
        </div>
      </div>
      <div class="lorebu">
        <button class="bton bt" id="login" @click="$router.push('/login')">
          登录
        </button>
        <button
          class="bton bt"
          id="register"
          @click="$router.push('/register')"
        >
          新用户注册
        </button>
        <button
          v-if="onelogin"
          class="bton bt"
          id="go"
          @click="$router.push('/home')"
        >
          一键返回<span class="ti">（8小时内登陆过）</span>
        </button>
        <!-- <p @click="$router.push('/register')" class="rega">注册</p> -->
      </div>
      <div class="icf">
        <div>
          <img src="../../assets/lg1.png" />
          <p class="pp">5年</p>
          <p>老品牌</p>
        </div>
        <div>
          <img src="../../assets/lg2.png" />
          <p class="pp">5万+</p>
          <p>企业</p>
        </div>
        <div>
          <img src="../../assets/lg3.png" />
          <p class="pp">10万+</p>
          <p>老板/人事</p>
        </div>
        <div>
          <img src="../../assets/lg4.png" />
          <p class="pp">20万份</p>
          <p>背调报告</p>
        </div>
      </div>
      <div class="allxiangmu">
        <div class="backx">
          <p>背景调查项目</p>
        </div>
        <div class="menuclass">
          <div>
            <div>
              <div class="di"></div>
              <p>身份信息核实</p>
            </div>
            <div>
              <div class="di"></div>
              <p>教育背景核实</p>
            </div>
          </div>
          <div>
            <div>
              <div class="di"></div>
              <p>劳动仲裁记录</p>
            </div>
            <div>
              <div class="di"></div>
              <p>限飞限乘查询</p>
            </div>
          </div>
          <div>
            <div>
              <div class="di"></div>
              <p>商业利益冲突查询</p>
            </div>
            <div>
              <div class="di"></div>
              <p>个人涉诉查询</p>
            </div>
          </div>
          <div>
            <div>
              <div class="di"></div>
              <p>金融监管处罚查询</p>
            </div>
            <div>
              <div class="di"></div>
              <p>更多定制服务</p>
            </div>
          </div>
        </div>
        <button id="buti" @click="$router.push('/home')">立即体验</button>
      </div>
      <p class="pbei">背调流程</p>
      <div class="allliu">
        <div>
          <img src="../../assets/ba1.png" />
          <p>确认候选人信息</p>
        </div>
        <div>
          <img src="../../assets/ba2.png" />
          <p>获得候选人授权</p>
        </div>
        <div>
          <img src="../../assets/ba3.png" />
          <p>查询国家数据库</p>
        </div>
        <div>
          <img src="../../assets/ba4.png" />
          <p>生成调查报告</p>
        </div>
      </div>
    </div>
    <div class="seall">
      <p>为什么选择萝卜猎手？</p>
      <p>精准</p>
      <p>对接国家权威数据库，让简历不再说谎</p>
      <p>高效</p>
      <p>随时随地，一键操作</p>
      <p>秒出报告，尽在掌握</p>
      <p>权威</p>
      <p>NAPBS（世界权威背景调查公司</p>
      <p>协会）正式成员</p>
    </div>
    <div class="buall">
      <button @click="$router.push('/login')" class="bton bo">登录</button>
      <button @click="$router.push('/register')" class="bton bo">注册</button>
    </div>
    <div class="newwd"></div>
    <sharepage></sharepage>
  </div>
</template>
<script>
import sharepage from "../../views/sharepage/index";
import loginmeth from "../../../util/logintime";
export default {
  components: {
    sharepage,
  },
  data() {
    return {
      onelogin: false,
    };
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
  },
  mounted() {
    this.islogintime();
    this.tosharemeth();
    if (this.$route.query.invitecode) {
      localStorage.setItem("invitecode", this.$route.query.invitecode);
    }
  },
  methods: {
    /**
     * 验证登录是否过期
     */
    islogintime() {
      this.onelogin = loginmeth();
    },
    tosharemeth() {
      let obj = JSON.stringify({
        sharetitle: "萝卜猎手背景调查",
        sharedesc: "一秒钟还原履历真实性，即刻体验~",
        isshare: "1",
        share_phone:localStorage.getItem("phone")
      });
      localStorage.setItem("shareobj", obj);
    },
  },
};
</script>
<style scoped>
.ti {
  font-size: 0.2rem;
}
.allmsg {
  background-color: #ffffff;
  padding-bottom: 0.43rem;
}
.logoimg {
  width: 2.24rem;
  margin-left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.4rem;
}
.rega {
  color: #f76729;
  font-size: 0.36rem;
  line-height: 0.32rem;
}
.lorebu {
  margin: 0 0.34rem;
}
.topmsg {
  text-align: center;
  font-size: 0.36rem;
  color: #333333;
  margin-bottom: 0.4rem;
}
.toptip {
  height: 5.6rem;
  width: 100%;
  background-image: url("../../assets/floor.png");
  background-size: 100% 100%;
  position: relative;
}
.toppbu {
  position: absolute;
  top: 0.68rem;
  width: 90%;
  margin-left: 50%;
  transform: translate(-50%);
}
.toppbu h2 {
  font-size: 0.34rem;
  color: #ffffff;
  text-align: center;
  line-height: 0.6rem;
}

#login {
  display: block;
  width: 100%;
  padding: 0.32rem 0;
  margin: 0;
  margin-bottom: 0.25rem;
  margin-top: 0.59rem;
  line-height: 0.32rem;
}
#register {
  width: 100%;
  padding: 0.32rem 0;
  margin: 0;
  line-height: 0.32rem;
  margin-bottom: 0.25rem;
}
#go {
  width: 100%;
  padding: 0.32rem 0;
  margin: 0;
  line-height: 0.32rem;
  margin-bottom: 0.25rem;
}
.bt {
  width: 100%;
  border-radius: 0.2rem;
  font-size: 0.36rem;
}
.icf {
  margin: 0.6rem 0.7rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.41rem;
}
.icf div {
  text-align: center;
}
.icf img {
  width: 0.8rem;
}
.icf .pp {
  margin-top: 0.18rem;
  color: #f76729;
}
.icf div p {
  line-height: 0.45rem;
  font-size: 0.3rem;
}
.allxiangmu {
  margin: 0.6rem 0.32rem 0.4rem 0.32rem;
  box-shadow: 0 0.05rem 0.14rem #c2c2c2;
  border-radius: 0.2rem;
  overflow: hidden;
}
.backx {
  padding: 0.26rem 0;
  background-color: #fc9b44;
}
.backx p {
  text-align: center;
  color: #ffffff;
  font-size: 0.34rem;
  line-height: 0.48rem;
}
.di {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #fc9b44;
  margin-right: 0.2rem;
}
.menuclass {
  margin: 0.45rem 0.88rem;
}
.menuclass > div {
  display: flex;
  justify-content: space-between;
}
.menuclass > div > div {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  color: #797979;
  line-height: 0.38rem;
}
#buti {
  margin: 0;
  margin-left: 50%;
  transform: translate(-50%);
  padding: 0.15rem 0.47rem;
  border: 1px solid #f55613;
  border-radius: 0.1rem;
  background-color: #ffffff;
  color: #f76729;
  font-size: 0.26rem;
  line-height: 0.3rem;
  margin-bottom: 0.28rem;
}
.pbei {
  text-align: center;
  margin: 0.4rem 0;
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
  font-weight: 500;
}
.allliu {
  margin: 0 0.33rem;
}
.allliu > div {
  border: 1px solid #f76729;
  border-radius: 0.1rem;
  color: #f76729;
  font-size: 0.28rem;
  display: flex;
  align-items: center;
  line-height: 0.4rem;
  padding: 0.21rem 0;
}
.allliu > div > img {
  width: 0.5rem;
  margin-right: 0.52rem;
  margin-left: 27%;
}
.allliu > div:nth-child(2),
.allliu > div:nth-child(3) {
  margin: 0.15rem 0;
}
.seall {
  background-color: #f5f7fa;
  padding-top: 0.51rem;
}
.seall p {
  text-align: center;
}
.seall > p:first-child {
  color: #333333;
  font-size: 0.34rem;
  line-height: 0.48rem;
}
.seall > p:nth-child(2) {
  margin-top: 0.4rem;
  color: #f55613;
  font-size: 0.34rem;
  line-height: 0.4rem;
}
.seall > p:nth-child(3) {
  margin-top: 0.13rem;
  color: #7c7c7c;
  font-size: 0.26rem;
  line-height: 0.4rem;
}
.seall > p:nth-child(4) {
  margin-top: 0.4rem;
  color: #f55613;
  font-size: 0.34rem;
  line-height: 0.4rem;
}
.seall > p:nth-child(5) {
  margin-top: 0.15rem;
  color: #7c7c7c;
  font-size: 0.26rem;
  line-height: 0.5rem;
}
.seall > p:nth-child(6) {
  color: #7c7c7c;
  font-size: 0.26rem;
  line-height: 0.5rem;
}
.seall > p:nth-child(7) {
  margin-top: 0.4rem;
  color: #f55613;
  font-size: 0.34rem;
  line-height: 0.4rem;
}
.seall > p:nth-child(8) {
  margin-top: 0.08rem;
  color: #7c7c7c;
  font-size: 0.26rem;
  line-height: 0.5rem;
}
.seall > p:nth-child(9) {
  color: #7c7c7c;
  font-size: 0.26rem;
  line-height: 0.5rem;
}
.buall {
  display: flex;
  margin-top: 0.4rem;
}
.bo {
  border-radius: 0.1rem;
}
.newwd {
  height: 0.76rem;
}
</style>